import "./Portfolio.scss";

function Portfolio() {
    return (
        <div className="portfolio-page-container">
            <h1>Portfolio page</h1>
        </div>
    );
}

export default Portfolio;