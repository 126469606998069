import "./Blog.scss";

function Blog() {
    return (
        <div className="blog-page-container">
            <h1>Blog page</h1>
        </div>
    );
}

export default Blog;