const experience =  [
    {
        "id": 0,
        "title": "languages",
        "data": [
            {
                "name": "HTML",
                "experience": 3,
                "proficiency": "proficient"
            },
            {
                "name": "CSS/Sass",
                "experience": 3,
                "proficiency": "proficient"
            },
            {
                "name": "JavaScript",
                "experience": 2,
                "proficiency": "proficient"
            },
            {
                "name": "Java",
                "experience": 3,
                "proficiency": "intermediate"
            },
            {
                "name": "Python",
                "experience": 2,
                "proficiency": "proficient"
            },
            {
                "name": "SQL",
                "experience": 1.5,
                "proficiency": "intermediate"
            },
            {
                "name": "C++",
                "experience": 3,
                "proficiency": "intermediate"
            },
        ],
    },
    {
        "id": 1,
        "title": "frameworks",
        "data": [
            {
                "name": "React",
                "experience": 2,
                "proficiency": "intermediate"
            },
            {
                "name": "Spring Boot",
                "experience": 1,
                "proficiency": "intermediate"
            },
            {
                "name": "Flask",
                "experience": 1,
                "proficiency": "intermediate"
            }
        ],
    },
    {
        "id": 2,
        "title": "software",
        "data": [
            {
                "name": "Figma",
                "experience": 2.5,
                "proficiency": "proficient"
            },
            {
                "name": "Git",
                "experience": 3,
                "proficiency": "proficient"
            },
            {
                "name": "Bash Shell",
                "experience": 3,
                "proficiency": "proficient"
            }
        ]
    }
];

export default experience; 